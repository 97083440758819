import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { apiClient, useAsyncFunction } from '../../../helpers/apiHelperV2';
import ReduxSuspense from '../ReduxSuspense/ReduxSuspense';
import InlineSpinner from '../Spinner/InlineSpinner';
import ProductCard from '../layout/ProductCard';
import {
    vehicleManufacturerSelector,
    vehicleSelectionLabelSelector,
    vehicleDataSelector
} from '../../../modules/selectors/vehicle';
import { slice } from 'lodash';
import Button from '../form-elements/Button';

function VehicleSelectionProducts() {
    const standortUrl = useSelector(standortUrlSelector);
    const vehicleManufacturer = useSelector(vehicleManufacturerSelector);
    const vehicleData = useSelector(vehicleDataSelector);
    const [index, setIndex] = useState(20);
    const [totalVehicle, setTotalVehicle] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);
    const label = useSelector(vehicleSelectionLabelSelector);


    const vehicleProductResponse = useAsyncFunction(async () => {
        const {
            data: { data },
        } = vehicleData.vehicleTypeLabel? 
        await apiClient.post(`/api/v1/standorte/${standortUrl}/vehicleSelectionProducts`, { marke: vehicleManufacturer, vehicle: vehicleData }) 
        :
        null;
        setTotalVehicle(data.length);
        return { data };
    }, [standortUrl, vehicleManufacturer, vehicleData]);


    const loadMore = () => {
        setIndex(index + 20);
        if (index >= totalVehicle) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    }

    return (
        <ReduxSuspense response={vehicleProductResponse} error={null} fallback={<InlineSpinner />}>
            {({ data }) => {
                const initialPosts = slice(data, 0, index);
                if (!data) return null;
                return (
                    <section className="uk-section uk-section-default uk-padding-remove-bottom">
                        <div className="uk-container">
                            <h3>{data.length} Artikel für folgendes Fahrzeug gefunden: { label }</h3>
                            <div>
                                <ul className="uk-grid uk-grid-match uk-child-width-1-2 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-5@l uk-margin-remove-left">
                                    {initialPosts.map(product => (
                                        <li className="uk-padding-small vehicle_products" key={product.id}>
                                            <ProductCard product={product} />
                                        </li>
                                    ))}
                                </ul>
                                {/* </div> */}
                            </div>
                            <div className="uk-container uk-text-center uk-margin-top">
                                {!isCompleted ? (
                                    <Button onClick={loadMore} type="button" modifier="primary">
                                        Mehr laden
                                    </Button>
                                ) : null}
                            </div>


                        </div>
                    </section>
                )
            }}
        </ReduxSuspense >
    )
}

export default VehicleSelectionProducts;