import _ from 'lodash';
import { SELECT_VEHICLE, SET_FILTER_FORM, SET_KBA_FORM, SET_HSN_TSN_FORM, SELECT_SEARCH_VEHICLE, SELECT_MANUAL_VEHICLE, SET_VEHICLE_MANUFACTURE } from '../actionTypes';

// Reference: https://www.dat.de/fileadmin/de/support/interface-documentation/DE/SilverDAT_Schnittstellen-Kompendium/index.htm#1917
const VEHICLE_TYPE_POSITIONS = 2;
const MANUFACTURER_POSITIONS = 3;
const BASE_MODEL_POSITIONS = 3;
const SUB_MODEL_POSITIONS = 3;
const SUB_TYPE_VARIANT_POSITIONS = 4;

const DAT_E_CODE_VALID_PREFIX_LENGTHS = [
    0,
    VEHICLE_TYPE_POSITIONS,
    VEHICLE_TYPE_POSITIONS + MANUFACTURER_POSITIONS,
    VEHICLE_TYPE_POSITIONS + MANUFACTURER_POSITIONS + BASE_MODEL_POSITIONS,
    VEHICLE_TYPE_POSITIONS + MANUFACTURER_POSITIONS + BASE_MODEL_POSITIONS + SUB_MODEL_POSITIONS,
    VEHICLE_TYPE_POSITIONS + MANUFACTURER_POSITIONS + BASE_MODEL_POSITIONS + SUB_MODEL_POSITIONS + SUB_TYPE_VARIANT_POSITIONS,
];

export const selectVehicle = ({ datECodePrefix, label }) => {
    if (!_.isString(datECodePrefix) || !DAT_E_CODE_VALID_PREFIX_LENGTHS.includes(datECodePrefix.length)) {
        return console.error('Invalid datECodePrefix:', datECodePrefix);
    }

    if (!_.isString(label)) {
        return console.error('Invalid label:', label);
    }

    return {
        type: SELECT_VEHICLE,
        payload: { datECodePrefix, label },
    };
};

export const selectVehicleManufacture = ({ vehicleManufacturer, vehicleTypeLabel, manufacturerLabel, baseModelLabel, datECodePrefix, subModelLabel, selectedLabel,  }) => {
    var marke = [];

    if (vehicleManufacturer) {
        marke.push("Universal", vehicleManufacturer);
    } else {
        marke = [];
    }
    return {
        type: SET_VEHICLE_MANUFACTURE,
        payload: {
            vehicleManufacturer: marke,
            vehicleTypeLabel: vehicleTypeLabel,
            manufacturerLabel: manufacturerLabel,
            baseModelLabel: baseModelLabel,
            subModelLabel: subModelLabel,
            datECodePrefix: datECodePrefix,
            selectedVehicleLabel: selectedLabel
        },
    };
}

export const selectManualVehicle = ({ datECodePrefix, label }) => {
    if (!_.isString(datECodePrefix) || !DAT_E_CODE_VALID_PREFIX_LENGTHS.includes(datECodePrefix.length)) {
        return console.error('Invalid datECodePrefix:', datECodePrefix);
    }

    if (!_.isString(label)) {
        return console.error('Invalid label:', label);
    }

    return {
        type: SELECT_MANUAL_VEHICLE,
        payload: { datECodePrefix, label },
    };
};

export const selectSearchVehicle = ({ datECodePrefix, label }) => {
    if (!_.isString(datECodePrefix) || !DAT_E_CODE_VALID_PREFIX_LENGTHS.includes(datECodePrefix.length)) {
        return console.error('Invalid datECodePrefix:', datECodePrefix);
    }

    if (!_.isString(label)) {
        return console.error('Invalid label:', label);
    }

    return {
        type: SELECT_SEARCH_VEHICLE,
        payload: { datECodePrefix, label },
    };
};

export const setFilterForm = ({ key, value }) => {
    return {
        type: SET_FILTER_FORM,
        payload: { key, value },
    };
};

export const setKbaForm = ({ key, value }) => {
    return {
        type: SET_KBA_FORM,
        payload: { key, value },
    };
};

export const sethsntsnForm = ({ vehicles }) => {
    return {
        type: SET_HSN_TSN_FORM,
        payload: { vehicles },
    };
};
