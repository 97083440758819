import { createSelector } from 'reselect';

const vehicleStateSelector = state => state.vehicle;

export const datECodePrefixSelector = createSelector([vehicleStateSelector], vehicleState => vehicleState.datECodePrefix);
export const vehicleSearchSelector = createSelector([vehicleStateSelector], vehicleState => vehicleState.searchFilter);
export const vehicleManualSelector = createSelector([vehicleStateSelector], vehicleState => vehicleState.manualFilter);
export const vehicleLabelSelector = createSelector([vehicleStateSelector], vehicleState => vehicleState.label);
export const vehicleFilterFormSelector = createSelector([vehicleStateSelector], vehicleState => vehicleState.filterForm);
export const vehicleKbaFormSelector = createSelector([vehicleStateSelector], vehicleState => vehicleState.kbaForm);
export const vehicleHsnTsnFormSelector = createSelector([vehicleStateSelector], vehicleState =>  vehicleState.hsntsnForm );
export const vehicleManufacturerSelector = createSelector([vehicleStateSelector], vehicleState =>  vehicleState.vehicleManufacturer );
export const vehicleDataSelector = createSelector([vehicleStateSelector], vehicleState =>  vehicleState.vehicleData );
export const vehicleSelectionLabelSelector = createSelector([vehicleStateSelector], vehicleState =>  vehicleState.selectedVehicleLabel );
