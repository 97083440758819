import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import _ from 'lodash';
import { createSelector } from 'reselect';
import classnames from 'classnames';
import { CategoryLink } from '../form-elements/Link';
import { categoriesSelector } from '../../../modules/selectors/standort/categories';

const allSubCategoriesSelector = createSelector([categoriesSelector], (categories = []) =>
    categories.flatMap(category =>
        category.subCategories.map(subCategory => (!subCategory.score ? { ...subCategory, score: 50 } : subCategory))
    )
);
const rankedSubCategoriesSelector = createSelector([allSubCategoriesSelector], allSubCategories => _.sortBy(allSubCategories, ['score']));

const sortByFirstCharOfTitle = subCategories => _.sortBy(subCategories, [subCategory => subCategory.title.charAt(0)]);

const SubCategoryLinks = () => {
    const [{ user }] = useCookies(['user']);
    const rankedSubCategories = useSelector(state => rankedSubCategoriesSelector(state, { user }));
    const subCategories = useMemo(() => sortByFirstCharOfTitle(rankedSubCategories.slice(0, 30)), [rankedSubCategories]);

    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                <div
                    className="uk-grid uk-grid-small uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-6@l"
                    data-uk-grid=""
                >
                    {subCategories.map((subCategory, i) => (
                        <div key={i} className={classnames({ 'uk-visible@s': i >= 12 })}>
                            <CategoryLink category={subCategory}>{subCategory.title}</CategoryLink>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SubCategoryLinks;
