import React from 'react';
import { frontloadConnect } from 'react-frontload';
import { connect } from 'react-redux';
import { offersSelector } from '../../../modules/selectors/standort/offers';
import { fetchOffersIfNeeded } from '../../../modules/actions/standort/offers';
import CategoryCard from './CategoryCard';

function OfferCategoryCards({ offers }) {
    if (!offers) return null;
    return (
        <section className="uk-section uk-section-default uk-section-small">
            <div className="uk-container">
                <h2 className="uk-text-light">Angebote im Online-Shop</h2>
                <h3>Stets die beste Auswahl</h3>
                <div className="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-4@m" data-uk-grid="">
                    {offers.map(category => (
                        <CategoryCard key={category._id} category={category} />
                    ))}
                </div>
            </div>
        </section>
    );
}

const frontload = async props => await Promise.all([props.fetchOffersIfNeeded()]);
const mapStateToProps = state => ({ offers: offersSelector(state) });
const mapDispatchToProps = { fetchOffersIfNeeded };

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(OfferCategoryCards));
