import React from 'react';

export default function Tooltips({ type, position = 'bottom-left', title = 'Tooltip', style }) {
    function renderSwitch() {
        switch (type) {
            
            case 'HSN/TSN':
                return (
                    <div className="sn-info uk-flex-middle" style={style}>
                        <i
                            className="fa fa-info-circle fa-lg"
                            aria-hidden="true"
                            // style={{ color: "rgb(45, 166, 223)" }}
                            uk-tooltip={`
                            pos: ${position}; 
                            cls: sn-tooltip uk-active;
                            title: <div>Die Herstellerschlüsselnummer (HSN) und die Typschlüsselnummer (TSN) finden Sie in Ihrem Fahrzeugschein im Feld 2.<img src='/HSN_TSN.png' />Bild: bussgeldkatalog.org</div>`}
                        ></i>
                    </div>
                );
            case 'HSN':
                return (
                    <div className="sn-info uk-flex-middle" style={style}>
                        <i
                            className="fa fa-info-circle fa-lg"
                            aria-hidden="true"
                            // style={{  color: "rgb(45, 166, 223)" }}
                            uk-tooltip={`
                            pos: ${position}; 
                            cls: sn-tooltip uk-active;
                            title: <div>Die Herstellerschlüsselnummer (HSN) und die Typschlüsselnummer (TSN) finden Sie in Ihrem Fahrzeugschein im Feld 2.<img src='/HSN.png' />Bild: bussgeldkatalog.org</div>`}
                        ></i>
                    </div>
                );
            case 'TSN':
                return (
                    <div className="sn-info uk-flex-middle" style={style}>
                        <i
                            className="fa fa-info-circle fa-lg"
                            aria-hidden="true"
                            // style={{  color: "rgb(45, 166, 223)" }}
                            uk-tooltip={`
                            pos: ${position}; 
                            cls: sn-tooltip uk-active;
                            title: <div>Die Herstellerschlüsselnummer (HSN) und die Typschlüsselnummer (TSN) finden Sie in Ihrem Fahrzeugschein im Feld 2.<img src='/TSN.png' />Bild: bussgeldkatalog.org</div>`}
                        ></i>
                    </div>
                );
            case 'FIN':
                return (
                    <div className="sn-info uk-flex-middle" style={style}>
                        <i
                            className="fa fa-info-circle fa-lg"
                            aria-hidden="true"
                            // style={{ color: "rgb(45, 166, 223)" }}
                            uk-tooltip={`
                            pos:  ${position}; 
                            cls: sn-tooltip uk-active;
                            container: body;
                            title: <div>Die FIN-Abfrage ermöglicht eine noch genauere Berechnung der Servicekosten im Vergleich zur Abfrage über HSN/TSN bzw. die manuelle Eingabe.<br><br>Die Fahrzeugidentifikationsnummer (FIN) finden Sie in Ihrem Fahrzeugschein (Zulassungsbescheinigung Teil I) im Feld E.<img src='/FIN.png' /></div>`}
                        ></i>
                    </div>
                );

            case 'Gesamtsumme':
                return (
                    <div className="sn-info uk-flex-middle" style={style}>
                        <i
                            className="fa fa-info-circle"
                            style={{ height: "min-content", backgroundColor:"inherit", borderRadius: "50%" , paddingLeft: '10px' }}
                            aria-hidden="true"
                            uk-tooltip="
                                    pos: bottom-left; 
                                    cls: sn-tooltip uk-active;
                                    container: body;
                                    title: <div>Die angegebene vorraussichtliche Gesamtsumme bezieht sich auf die ausgewiesenen Services und Teile und kann Änderungen unterliegen, die sich erst vor Ort in Ihrer Werkstatt ergeben. Ggf. können vor Ort zusätzliche Leistungen notwendig werden, für die weitere Kosten entstehen können. Sollten Zusatzprodukte angeboten und gewählt worden sein, werden diese nicht in der Gesamtsumme aufgeführt und vor Ort berechnet.</div>"
                        ></i>
                    </div>
                );
            default:
                return (
                    
                    <>
                    {title != 'Tooltip' && title != '' && (
                    <div className="sn-info uk-flex-middle" style={style}>
                        <i
                            className="fa fa-info-circle fa-lg"
                            aria-hidden="true"
                            style={{ height: "min-content", backgroundColor:"white", borderRadius: "50%" }}
                            uk-tooltip={`
                            pos:  ${position}; 
                            cls: sn-tooltip uk-active;
                            container: body;
                            title: <div>${title.replace(/\n{1}/g,'<br>') }</div>`}
                        ></i>
                    </div>
                    )}
                    </>
                );
        }
    }
    return <>{renderSwitch()}</>;
}
