import React from 'react';
import Anchor from '../form-elements/Anchor';

function CardSlider({ children }) {
    return (
        <div className="uk-padding uk-slider" data-uk-slider="">
            <div className="uk-position-relative" tabIndex="-1">
                <div className="uk-slider-container-offset uk-slider-container">
                    <ul className="uk-slider-items uk-grid uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m" data-uk-grid="">
                        {children}
                    </ul>
                </div>
                <Anchor className="uk-position-center-left-out" data-uk-slider-item="previous">
                    <i data-uk-slidenav-previous="" />
                </Anchor>
                <Anchor className="uk-position-center-right-out" data-uk-slider-item="next">
                    <i data-uk-slidenav-next="" />
                </Anchor>
            </div>
        </div>
    );
}

export default CardSlider;
