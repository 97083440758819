import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import Image, { useFallbackImageSrc } from '../Image/Image';
import Vbox from '../Vbox/Vbox';
import Anchor from '../form-elements/Anchor';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { previewSelector } from '../../../modules/selectors/standort/preview';
import { fetchPreviewIfNeeded } from '../../../modules/actions/standort/preview';
import { frontloadConnect } from 'react-frontload';

function HomeHeroBanner({ preview }) {
    const markenStandort = useSelector(markenStandortSelector);
    const [mobileTeaserSrc, mobileTeaserOnError] = useFallbackImageSrc({
        src: `https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/MobileTeaserImg.jpg`,
        fallbackSrc: 'https://techno-images.s3.eu-central-1.amazonaws.com/national/teaserbild_m1.jpg',
    });

    const slides = useMemo(() => preview?.slides, [preview]);
    
    return (
        <section className="uk-section-default" style={{ marginTop: 20}}>
            <div className="uk-container uk-padding-remove">
                <div className="uk-hidden@s uk-position-relative">
                    <Image
                        src={mobileTeaserSrc}
                        alt={`${markenStandort.areaTitle} ${markenStandort.headerSlogan}`}
                        onError={mobileTeaserOnError}
                        resizeWidth={640} // based on uk-breakpoint-small (640px)
                    />
                    <div className="uk-overlay uk-position-top" style={{ textShadow: '1px 1px 0px #fff' }}>
                        {markenStandort.isTechno && (
                            <h3>
                                Die beste Adresse <br /> Für Ihr Auto
                            </h3>
                        )}
                        {/* {!markenStandort.isTechno && markenStandort.slogan && (
                            <h3 dangerouslySetInnerHTML={{ __html: markenStandort.slogan }} />
                        )} */}
                    </div>
                </div>
                {slides && (
                    <div
                        className="uk-position-relative uk-slider uk-slider-container uk-light uk-visible@s uk-height-1-1 uk-height-1-1"
                        tabIndex="-1"
                        data-uk-slider="autoplay: true"
                    >
                        <ul className="uk-slider-items uk-grid uk-child-width-1-1 uk-height-1-1">
                            {slides.map(item => (
                                <li key={item._id} tabIndex="-1" style={{ order: 1 }}>
                                    <Vbox item={item} type="slider" />
                                </li>
                            ))}
                        </ul>
                        <Anchor className="uk-position-center-left uk-position-small" data-uk-slider-item="previous">
                            <i className="fa fa-2x fa-chevron-left" />
                        </Anchor>
                        <Anchor className="uk-position-center-right uk-position-small" data-uk-slider-item="next">
                            <i className="fa fa-2x fa-chevron-right" />
                        </Anchor>
                    </div>
                )}
            </div>
        </section>
    );
}

const previewCategories = ['slide'];
const frontload = async props => await Promise.all([props.fetchPreviewIfNeeded({ previewCategories })]);
const mapStateToProps = state => ({ preview: previewSelector(state, { previewCategories }) });
const mapDispatchToProps = { fetchPreviewIfNeeded };

export default connect(mapStateToProps, mapDispatchToProps)(frontloadConnect(frontload, { onUpdate: true })(HomeHeroBanner));
