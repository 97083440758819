import React from 'react';
import { useSelector } from 'react-redux';
import Image from '../Image/Image';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { ContactPhone } from './Contact';
import Button from '../form-elements/Button';
import ContactModal from '../Anfrage/ContactModal';

function Meisterkachel() {
    const markenStandort = useSelector(markenStandortSelector);

    return (
        <div className="uk-tile uk-tile-muted uk-padding-small uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <div className="uk-grid uk-grid-small uk-width-1-1 uk-flex uk-flex-middle" data-uk-grid="">
                <div className="uk-width-auto@s uk-flex uk-flex-center">
                    <Image
                        className="uk-border-circle"
                        src={`https://techno-images.s3.eu-central-1.amazonaws.com/standort-images/${markenStandort.gesellschafterNr}/${markenStandort.standortNr}/Meister.png`}
                        alt="Meisterkachel"
                        resizeWidth={240}
                        resizeHeight={240}
                    />
                </div>
                <div className="uk-width-expand">
                    <div className="uk-padding-small uk-text-large uk-text-center uk-text-bold">
                        Sie haben nicht das gefunden, was Sie gesucht haben oder haben Fragen zu einem Produkt oder Service? Sprechen Sie
                        uns gerne an.
                    </div>
                    <div
                        className="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-auto@s uk-flex uk-flex-center uk-light"
                        data-uk-grid=""
                    >
                        <div className="uk-flex-auto">
                            <ContactPhone modifier="secondary" hideArrow fullWidth />
                        </div>
                        <div className="uk-flex-auto">
                            <ContactModal>
                                {({ openModal }) => (
                                    <Button
                                        modifier="secondary"
                                        hideArrow
                                        fullWidth
                                        className="uk-flex uk-flex-center uk-flex-middle"
                                        onClick={openModal}
                                    >
                                        <i className="uk-margin-small-right" data-uk-icon="mail" /> Kontaktformular
                                    </Button>
                                )}
                            </ContactModal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Meisterkachel;
