import { offersResponseSelector } from '../../selectors/standort/offers';
import { shouldFetch } from '../../fetchStatus';
import { FETCH_OFFERS_FAILURE, FETCH_OFFERS_REQUEST, FETCH_OFFERS_SUCCESS } from '../../actionTypes';
import { apiClient } from '../../../helpers/apiHelperV2';
import { standortUrlSelector } from '../../selectors/url';

export const fetchOffersIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const offersResponse = offersResponseSelector(state);

        if (shouldFetch(offersResponse.status)) {
            dispatch({
                type: FETCH_OFFERS_REQUEST,
                payload: { standortUrl },
            });
            try {
                const res = await apiClient.get('/api/v1/Offers', {
                    params: {
                        standortUrl,
                    },
                });
                dispatch({
                    type: FETCH_OFFERS_SUCCESS,
                    payload: { standortUrl, offers: res.data.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_OFFERS_FAILURE,
                    payload: { standortUrl, error },
                });
            }
        }
    };
};
