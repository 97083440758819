import React from 'react';
import Image from '../Image/Image';
import { CategoryLink } from '../form-elements/Link';

function CategoryCard({ category }) {
    return (
        <CategoryLink category={category} modifier="reset">
            <div className="uk-card uk-card-small uk-text-center uk-card-default">
                <Image
                    className="uk-flex uk-flex-center uk-width-1-1"
                    src={`https://techno-images.s3.eu-central-1.amazonaws.com/cat-images/klein/${category.url}.jpg`}
                    alt={category.title}
                />
                <div className="uk-card-footer uk-card-secondary uk-text-truncate">{category.title.toUpperCase()}</div>
            </div>
        </CategoryLink>
    );
}

export default CategoryCard;
