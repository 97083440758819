import React from 'react';
import _ from 'lodash';

function Ribbon({ product, isOfferActive }) {
    if(!_.isNil(product.stock) && product.stock < 1 ){
        product.soldOut = true
    }
    if(!product.soldOut && 
        (!product.promotion || product.promotion === 'none' ||  
            (product.originalPrice && product.preisAb && product.originalPrice === product.preisAb)  || 
            !isOfferActive || ((product.aktionsPreis && product.aktionsPreis >= product.vk_preis) && 
            (product.aktionsPreisPickup && product.aktionsPreisPickup >= product.pickup_preis))
        ) && !product.showRemainingStock 
        
    ) {
        return null;
    }

    return (
        <>
        {product.soldOut &&  <div className="uk-text-default uk-position-z-index uk-position-top-left aps-soldout-ribbon soldout-theme">Bald wieder lieferbar</div> }
        {!product.soldOut && product.showRemainingStock &&  <div className="uk-text-bold uk-position-z-index uk-position-top-left aps-stock-ribbon stock-theme">Nur noch {product.stock} auf Lager</div> }
        {!product.soldOut && !product.showRemainingStock &&
        <div className="aps-ribbon aps-ribbon-top-left uk-position-z-index uk-position-top-left">
            <div style={{ top: 27, right: -25 }}>
                { product.promotion !== 'none' && <span className="uk-text-bold">ANGEBOT</span>}
                { product.promotion === 'some' && <div className="uk-text-small">für ausgewählte Produkte</div>}
            </div>
        </div>
        }
        </>
    );
}

export default Ribbon;
