import React from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { markenStandortThemeSelector } from '../../../modules/selectors/standort/theme';

const LegacyBreadCrumb = loadable(() => import('./LegacyBreadCrumb'));
const Breadcrumb = loadable(() => import('./BreadCrumb'));

function BreadCrumbSelector({ additionalCrumbs, showOptionsSidebarToggle }) {
    const markenStandortTheme = useSelector(markenStandortThemeSelector);

    switch (markenStandortTheme.componentName) {
        case 'LegacyTheme':
            return <LegacyBreadCrumb additionalCrumbs={additionalCrumbs} showOptionsSidebarToggle={showOptionsSidebarToggle} />;
        default:
            return <Breadcrumb additionalCrumbs={additionalCrumbs} showOptionsSidebarToggle={showOptionsSidebarToggle} />;
    }
}

export default BreadCrumbSelector;
